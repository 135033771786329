import React from 'react'

const NoCaseFound = () => {
  return (
    <div className='flex h-full flex-col items-center justify-center'>
      <div className='flex items-center justify-center rounded-full bg-red-100 p-4'>
        {/* Replace the emoji with the appropriate SVG or image icon */}
        <svg
          className='h-12 w-12 text-red-500'
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M12 8v4m0 4h.01M21 12c0 4.971-4.029 9-9 9s-9-4.029-9-9 4.029-9 9-9 9 4.029 9 9z'
          />
        </svg>
      </div>
      <h2 className='mt-4 text-lg font-semibold'>No Case Found</h2>
      <p className='mt-2 text-center text-gray-500'>
        No Case Found for this source
      </p>
    </div>
  )
}

export default NoCaseFound
